import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";

const Connect = () => {
  const profiles = [
    {
      id: 1,
      img: [
        "/assets/images/w3tinder/slider/pic1.png",
        "/assets/images/w3tinder/slider/pic1.png",
        "/assets/images/w3tinder/slider/pic1.png",
      ],
      name: "Harleen",
      age: 24,
      location: "3 miles away",
      badge: "Nearby",
    },
    {
      id: 2,
      img: ["/assets/images/w3tinder/slider/pic3.png"],

      name: "Richard",
      age: 21,
      location: "5 miles away",
      badge: "Nearby",
    },
    {
      id: 3,
      img: ["/assets/images/w3tinder/slider/pic2.png"],
      name: "Natasha",
      age: 22,
      location: "2 miles away",
    },
    {
      id: 4,
      img: ["/assets/images/w3tinder/slider/pic3.png"],
      name: "Lisa Ray",
      age: 25,
      interests: ["Photography", "Street Food", "Foodie Tour"],
    },
    {
      id: 5,
      img: ["/assets/images/w3tinder/slider/pic4.png"],
      name: "Richard",
      age: 22,
      badge: "New here",
      interests: ["Climbing", "Skincare", "Dancing", "Gymnastics"],
    },
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const handleNextImage = (userId, imagesLength) => {
    setCurrentImageIndex((prevState) => ({
      ...prevState,
      [userId]: (prevState[userId] + 1) % imagesLength || 0,
    }));
  };

  const handlePreviousImage = (userId, imagesLength) => {
    setCurrentImageIndex((prevState) => ({
      ...prevState,
      [userId]:
        (prevState[userId] - 1 + imagesLength) % imagesLength ||
        imagesLength - 1,
    }));
  };

  return (
    <div>
      {/* Header */}
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Page Content Start */}
      <div className="page-content space-top p-b10">
        <div className="container fixed-full-area">
          <Swiper
            grabCursor={true}
            modules={[EffectCards]}
            className="dzSwipe_card-cont dz-gallery-slider"
          >
            {profiles.map((profile) => (
              <SwiperSlide className="dzSwipe_card" key={profile.id}>
                <div className="dz-media">
                  <img
                    src={profile.img[currentImageIndex[profile.id] || 0]}
                    alt={`${profile.name}'s image`}
                  />
                  <div className="image-slider-buttons">
                    <button
                      onClick={() =>
                        handlePreviousImage(profile.id, profile.img.length)
                      }
                      className="previous-button"
                    >
                      <i className="icon feather icon-chevron-left"></i>
                    </button>
                    <button
                      onClick={() =>
                        handleNextImage(profile.id, profile.img.length)
                      }
                      className="next-button"
                    >
                      <i className="icon feather icon-chevron-right"></i>
                    </button>
                  </div>
                </div>
                <div className="dz-content">
                  <div className="left-content">
                    <span className="badge badge-primary d-inline-flex gap-1 mb-2">
                      <i className="icon feather icon-map-pin"></i>
                      <a
                        href="/profile-detail"
                        className="text-decoration-none text-white"
                      >
                        View more
                      </a>
                    </span>
                    <h4 className="title">
                      <a
                        href="/profile-detail"
                        className="text-decoration-none"
                      >
                        {profile.name}, {profile.age}
                      </a>
                    </h4>
                    <ul className="actionss">
                      <li>
                        <span className="badge">Like</span>
                      </li>
                      <li>
                        <span className="badge">follow</span>
                      </li>
                      <li>
                        <span className="badge">Message</span>
                      </li>
                    </ul>
                  </div>
                  <a
                    href="javascript:void(0);"
                    className="text-decoration-none dz-icon dz-sp-like"
                  >
                    <i className="flaticon flaticon-star-1"></i>
                  </a>
                </div>
                <div className="dzSwipe_card__drag"></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {/* Page Content End */}
    </div>
  );
};

export default Connect;
