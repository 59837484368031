import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";

// Fonts
import "./fonts/Providence.ttf";

import RootLayout from "./pages/Root";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ConnectPage from "./pages/Connect";
import ProfilePage from "./pages/Profile";
import ReferralPage from "./components/Referral/Referral";
import GetStarted from "./components/Authentication/GetStarted";

import ProfileDetail from "./components/Profile/ProfileDetail";
import SubscriptionPage from "./components/subscription/Packages";
import SettingsPage from "./pages/Settings";
import CommentPage from "./pages/comment";
import WalletPage from "./pages/Wallet";
import CreatePage from "./pages/Create";
import ContactPage from "./pages/Contact";
import ChatPage from "./pages/Chat";
import { Signup } from "./components/Authentication/Signup";
import { EmailOTP } from "./components/Authentication/EmailOTP";
import { Login } from "./components/Authentication/Login";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "comment",
        element: <CommentPage />,
      },
      {
        path: "get-started",
        element: <GetStarted />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "email-otp",
        element: <EmailOTP />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "about-us",
        element: <AboutPage />,
      },
      {
        path: "refer-and-earn",
        element: (
          <ProtectedRoute>
            <ReferralPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "connect",
        element: <ConnectPage />,
      },
      {
        path: "settings",
        element: (
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "create",
        element: <CreatePage />,
      },
      {
        path: "contact-us",
        element: <ContactPage />,
      },
      {
        path: "chat",
        element: (
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "subscribe",
        element: (
          <ProtectedRoute>
            <SubscriptionPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        ),
      },

      {
        path: "profile-detail",
        element: (
          <ProtectedRoute>
            <ProfileDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "wallet",
        element: (
          <ProtectedRoute>
            <WalletPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const App = () => {
  return (
    <>
      {/* Add ToastContainer here */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Provide the router */}
      <RouterProvider router={router} />
    </>
  );
};

export default App;
