import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef } from "react";

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#revivalAcademy") {
      const element = document.getElementById("revivalAcademy");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (location.hash === "#womenWithFire") {
      const element = document.getElementById("womenWithFire");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (location.hash === "#aboutUs") {
      const element = document.getElementById("aboutUs");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <section>
        <div className="py-56 bg-pages-heroBg bg-no-repeat bg-cover bg-center" />
        <div className="pt-12 pb-14 px-6 sm:px-12 md:px-20 lg:px-16 xl:px-28">
          <div className="flex flex-col items-center mb-10">
            <h2
              className="mb-2 text-center text-4xl font-bold uppercase md:text-5xl"
              id="aboutUs"
            >
              About Us
            </h2>
            <div className="w-10 h-[3px] bg-[#bd0103]" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
