// // import HeroContact from "../components/Home/HeroContact";
import Reels from "../components/Home/Reels";
// import Summary from "../components/Home/Summary/Summary";
// import Projects from "../components/Home/Projects";
// import Mission from "../components/Home/Mission";
// // import Others from "../components/Home/Others";
// import MakeADonation from "../components/Home/MakeADonation/MakeADonation";
// import Founder from "../components/Home/Founder";
// // import Partners from "../components/Home/Partners";
// // import Milestone from "../components/Home/Milestone";

const HomePage = () => {
  return (
    <>
      <Reels />
    </>
  );
};

export default HomePage;
