import { useState } from "react";
import axios from "axios";
import { requestOTP, verifyOTP } from "../../api/apiService";
import { useNavigate } from "react-router-dom";

export const EmailOTP = () => {
  const [email, setOTP] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = async () => {
    try {
      // Final step: Complete signup
      const data = await requestOTP(email, referralCode);
      userId = data.data.userId;
      console.log(response.data);
      navigate("/signup-otp");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Signup failed. Please try again."
      );
    }
  };

  return (
    <div className="page-content">
      <div className="container">
        <div className="account-area style-2">
          <form>
            <div
              className="bg-white input-group input-group-icon input-mini"
              style={{
                marginBottom: "16px",
              }}
            >
              <label
                style={{
                  display: "block",
                  fontWeight: "600",
                  marginBottom: "8px",
                  color: "#555",
                }}
              >
                Enter OTP
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your email"
                style={{
                  width: "100%",
                  padding: "10px",
                }}
                value={email}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
            <div
              className="bg-white input-group input-group-icon input-mini"
              style={{
                marginBottom: "16px",
              }}
            >
              <label
                style={{
                  display: "block",
                  fontWeight: "600",
                  marginBottom: "8px",
                  color: "#555",
                }}
              >
                Referral Code
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter referral code"
                style={{
                  width: "100%",
                  padding: "10px",
                }}
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="footer fixed bg-white p-b15">
          <div className="container">
            <a
              href="javascript:void(0);"
              className="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
            >
              Submit
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
