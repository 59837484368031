import React, { useState } from "react";

const ChatContact = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const matches = [
    { id: 1, name: "Grayson", image: "/assets/images/user/pic1.jpg" },
    { id: 2, name: "Tenzing", image: "/assets/images/user/pic2.jpg" },
    { id: 3, name: "Elisha", image: "/assets/images/user/pic3.jpg" },
    { id: 4, name: "Mitchell", image: "/assets/images/user/pic4.jpg" },
    { id: 5, name: "Stephon", image: "/assets/images/user/pic5.jpg" },
  ];

  const messages = [
    {
      id: 1,
      name: "Leneve",
      lastMessage: "Would love to!",
      time: "2m ago",
      image: "/assets/images/user/pic1.jpg",
    },
    {
      id: 2,
      name: "Matt",
      lastMessage: "Is that because we like the...",
      time: "4m ago",
      image: "/assets/images/user/pic2.jpg",
    },
    {
      id: 3,
      name: "Karthik",
      lastMessage: "How do you know John?",
      time: "8h ago",
      image: "/assets/images/user/pic3.jpg",
    },
    {
      id: 4,
      name: "Elisha",
      lastMessage: "Have you even been...",
      time: "1d ago",
      image: "/assets/images/user/pic4.jpg",
    },
  ];

  const filteredMessages = messages.filter((message) =>
    message.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {/* Header */}
      <div className="header header-fixed border-0 style-2 bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h4 className="title">Chats</h4>
            </div>
          </div>
        </div>
      </div>

      {/* Page Content */}
      <div className="page-content p-t5 p-b50">
        <div className="container">
          {/* Search Bar */}
          <div className="dz-chat-search bg-white input-group input-group-icon input-mini">
            <div className="input-group-text">
              <div className="input-icon">
                <i className="icon text-primary opacity-100 feather icon-search"></i>
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Search here..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* New Matches */}
          <h6 className="title mt-3 mb-3">New Matches</h6>
          <div className="swiper chat-swiper">
            <div className="swiper-wrapper">
              {matches.map((match) => (
                <div
                  className="swiper-slide d-flex flex-column align-items-center justify-content-center"
                  key={match.id}
                  style={{
                    margin: "0 10px",
                    textAlign: "center",
                  }}
                >
                  <a href="/chat" className="text-decoration-none recent">
                    <div
                      className="media media-60 rounded-circle"
                      style={{
                        width: "60px",
                        height: "60px",
                        overflow: "hidden",
                        marginBottom: "5px",
                      }}
                    >
                      <img
                        src={match.image}
                        alt={match.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <span style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                      {match.name}
                    </span>
                  </a>
                </div>
              ))}
            </div>
          </div>

          {/* Messages */}
          <div className="title-bar">
            <h6 className="title">Messages</h6>
          </div>
          <ul className="message-list">
            {filteredMessages.map((message) => (
              <li key={message.id}>
                <a href="/chat" className="text-decoration-none recent">
                  <div className="media media-60">
                    <img src={message.image} alt={message.name} />
                  </div>
                  <div className="media-content">
                    <div>
                      <h6 className="name">{message.name}</h6>
                      <p className="last-msg">{message.lastMessage}</p>
                    </div>
                    <div className="right-content">
                      <span className="date">{message.time}</span>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChatContact;
