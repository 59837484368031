import CommentsSection from "../components/Home/Comment";

const commentsData = [
  {
    username: "mr_sheep",
    profilePic: "https://i.postimg.cc/Y07dwFWC/user-1.jpg",
    timestamp: "2 yr ago (edited)",
    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    likes: 5300,
    dislikes: 0,
    replies: [
      {
        username: "reply_user1",
        content: "I agree with this comment!",
      },
      {
        username: "reply_user2",
        content: "Nice insight!",
      },
    ],
  },
  {
    username: "loverhair",
    profilePic: "https://i.postimg.cc/KjK8xKSC/user-2.png",
    timestamp: "1 yr ago (edited)",
    content: "Another great point!",
    likes: 2900,
    dislikes: 0,
    replies: [],
  },
];
const CommentPage = () => {
  return <CommentsSection commentsData={commentsData} />;
};

export default CommentPage;
