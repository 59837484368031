import React, { useEffect, useState } from "react";
import {
  getBalance,
  updateUserProfile,
  fetchUserProfile,
  getImages,
} from "../../api/apiService";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Profile = () => {
  const [starCoinBalance, setStarCoinBalance] = useState(0);
  const [usdtEquivalent, setUsdtEquivalent] = useState(0);
  const [userName, setUserName] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userCity, setUserCity] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [activeTab, setActiveTab] = useState("photos"); // Default to photos
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "day") setDay(value);
    if (name === "month") setMonth(value);
    if (name === "year") setYear(value);
  };

  const combinedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;
  // const photos = [
  //   { url: "/assets/images/liked/pic1.png" },
  //   { url: "/assets/images/liked/pic2.png" },
  //   { url: "/assets/images/liked/pic3.png" },
  // ];

  // const videos = [
  //   { url: "/assets/videos/video1.mp4" },
  //   { url: "/assets/videos/video2.mp4" },
  // ];

  // const [activeTab, setActiveTab] = useState("photos");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetchUserProfile();
        const profileImg = response.user.profilePicture;
        setProfileImage(profileImg);
      } catch (error) {
        console.error("Error fetching User info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const name = userObject.userName;
        // const age = userObject.age;
        const city = userObject.city;
        setUserName(name);
        // setUserAge(age);
        setUserCity(city);
      } catch (error) {
        console.error("Error fetching User info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await getImages(); // Fetch data from the endpoint
        console.log("Response from API:", response); // Log the response

        // Validate the response structure
        if (!response || !Array.isArray(response)) {
          console.error("Unexpected response structure:", response);
          return;
        }

        // Extract photo URLs and other details
        const photoMedia = response.map((item) => ({
          url: item.filepath,
          description: item.desc,
          likes: item.likes,
          uploadedAt: item.uploadedAt,
          ...item,
        }));

        setPhotos(photoMedia); // Store photos in state
      } catch (error) {
        console.error("Error fetching photos:", error);
      }
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
    const dummyVideos = [
      { url: "https://www.w3schools.com/html/mov_bbb.mp4" },
      { url: "https://www.w3schools.com/html/movie.mp4" },
    ];
    setVideos(dummyVideos);
  }, []);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Get values from form
    const bio = document.getElementById("aboutMe").value.trim();
    const interestsInput = document.getElementById("interests").value;
    const interests = interestsInput
      .split(",")
      .map((interest) => interest.trim())
      .filter((interest) => interest);
    const profileImageFile = document.getElementById("profileImage").files[0];
    // const dateOfBirth = document.getElementById("dateOfBirth").value;

    try {
      // Validation for interests
      if (!Array.isArray(interests)) {
        throw new Error("Please enter valid interests separated by commas.");
      }

      // Call the `updateUserProfile` function with the correct arguments
      await updateUserProfile(profileImageFile, bio, interests, dateOfBirth);

      // Display success toast notification
      toast.success("Profile updated successfully!", {
        position: "top-center",
      });
    } catch (error) {
      console.error("Error updating profile:", error);

      // Display error toast notification
      const errorMessage =
        error.response?.data?.message ||
        "Failed to update profile. Please try again.";
      toast.error(errorMessage, {
        position: "top-center",
      });
    } finally {
      setIsLoading(false); // Hide loading spinner
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("referral");
    window.location.href = "/login";
  };
  return (
    <div>
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h6 className="title">Profile</h6>
            </div>
            <div className="mid-content"></div>
            <div className="right-content"></div>
          </div>
        </div>
      </div>

      <div className="page-content space-top p-b60">
        <div className="container pt-1">
          <div className="profile-area">
            <div className="main-profile">
              <div className="about-profile">
                <a
                  href="/profile"
                  className="setting dz-icon"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#settingCanvas"
                  aria-controls="settingCanvas"
                >
                  <i className="fa fa-usd" aria-hidden="true"></i>
                </a>
                <div className="media rounded-circle">
                  <img
                    src={profileImage}
                    alt="profile-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "/assets/images/liked/pic2.png";
                    }}
                  />
                  <div className="data-fill">
                    <span>40%</span>
                  </div>
                </div>
                <a
                  href="#"
                  className="text-decoration-none edit-profile dz-icon"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#profileEditCanvas"
                  aria-controls="profileEditCanvas"
                >
                  <i className="flaticon flaticon-pencil-2"></i>
                </a>
              </div>
              <div className="profile-detail">
                <h5 className="name">{userName}, 20</h5>
                <p className="mb-0">
                  <i className="icon feather icon-map-pin me-1"></i> {userCity},
                  {userCity}
                </p>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom"
                      aria-controls="offcanvasBottom"
                      className="text-decoration-none"
                    >
                      <div className="card-icon">
                        <i className="flaticon flaticon-star-1"></i>
                      </div>
                      <div className="card-content">
                        <p>0 Likes</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <div className="card-icon">
                      <i className="fa fa-eye"></i>
                    </div>
                    <div className="card-content">
                      <p>0 Views</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <a href="/profile" className="text-decoration-none">
                      <div className="card-icon">
                        <i className="flaticon flaticon-bell"></i>
                      </div>
                      <div className="card-content">
                        <p>0 Follower</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="media-gallery">
              {/* Toggle Buttons */}
              <div className="d-flex justify-content-center mb-4">
                <button
                  className={`btn btn-toggle ${
                    activeTab === "photos" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("photos")}
                >
                  Photos
                </button>
                <button
                  className={`btn btn-toggle ${
                    activeTab === "videos" ? "active" : ""
                  }`}
                  onClick={() => handleTabChange("videos")}
                >
                  Videos
                </button>
              </div>

              {/* Media Grid */}
              <div className="row g-3">
                {(activeTab === "photos" ? photos : videos).map(
                  (media, index) => (
                    <div className="col-6 col-md-4" key={index}>
                      <div className="media-card">
                        {activeTab === "photos" ? (
                          <img
                            src={media.url}
                            alt={`Photo ${index + 1}`}
                            className="media-img"
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "8px",
                            }}
                          />
                        ) : (
                          <video
                            controls
                            className="media-video"
                            style={{ width: "100%" }}
                          >
                            <source src={media.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Canvas sections */}
      <div
        className="offcanvas offcanvas-bottom container p-10"
        style={{ height: "80vh" }}
        tabindex="-1"
        id="settingCanvas"
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header share-style m-0">
          <h5 className="title mb-0">Discover more...</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div
          className="offcanvas-body"
          style={{
            padding: "20px",
            backgroundColor: "#f8f9fa",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <div className="dz-list">
            <ul
              style={{
                listStyleType: "none",
                padding: "0",
                margin: "0",
              }}
            >
              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/settings"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  >
                    <i className="icon feather icon-user"></i>
                  </div>
                  <div className="dz-inner">
                    <h6
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Settings
                    </h6>
                  </div>
                </a>
              </li>

              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/wallet"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  >
                    <i class="icon feather icon-credit-card"></i>
                  </div>
                  <div className="dz-inner">
                    <h6
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Wallet
                    </h6>
                  </div>
                </a>
              </li>

              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/refer-and-earn"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  >
                    <i className="icon feather icon-bell"></i>
                  </div>
                  <div className="dz-inner">
                    <h6
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Refer and Earn
                    </h6>
                  </div>
                </a>
              </li>
              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/referal"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  ></div>
                  <div className="dz-inner">
                    <h6
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Become a creator
                    </h6>
                  </div>
                </a>
              </li>

              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/subscribe"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  >
                    <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                  </div>
                  <div className="dz-inner">
                    <h6
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Go Premium
                    </h6>
                  </div>
                </a>
              </li>
              <li style={{ marginBottom: "15px" }}>
                <a
                  href="/get-started"
                  className="item-content item-link"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "#212529",
                    padding: "10px",
                    borderRadius: "5px",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e9ecef")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "inherit")
                  }
                >
                  <div
                    className="dz-icon icon-sm"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "#495057",
                    }}
                  >
                    <i class="icon feather icon-log-out"></i>
                  </div>
                  <div className="dz-inner">
                    <h6
                      onClick={handleLogout}
                      className="dz-title"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Logout
                    </h6>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-bottom"
        style={{ height: "80vh" }}
        tabindex="-1"
        id="profileEditCanvas"
        aria-labelledby="profileEditCanvasLabel"
      >
        <div className="offcanvas-header">
          <h5 id="profileEditCanvasLabel" className="mb-0">
            Edit Profile
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body" style={{ padding: "20px" }}>
          <form onSubmit={handleProfileUpdate}>
            <div className="mb-3">
              <label htmlFor="profileImage" className="form-label">
                Profile Image
              </label>
              <input
                type="file"
                className="form-control"
                id="profileImage"
                accept="image/*"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="aboutMe" className="form-label">
                About Me
              </label>
              <textarea
                className="form-control"
                id="aboutMe"
                rows="4"
                placeholder="Tell us about yourself..."
              ></textarea>
            </div>
            {/* <div className="mb-3">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth:
              </label>
              <div className="d-flex">
                <select
                  name="day"
                  value={day}
                  onChange={handleDateChange}
                  className="form-select me-2"
                >
                  <option value="">Day</option>
                  {Array.from({ length: 31 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
                <select
                  name="month"
                  value={month}
                  onChange={handleDateChange}
                  className="form-select me-2"
                >
                  <option value="">Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {new Date(0, i).toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </select>
                <select
                  name="year"
                  value={year}
                  onChange={handleDateChange}
                  className="form-select"
                >
                  <option value="">Year</option>
                  {Array.from({ length: 100 }, (_, i) => (
                    <option key={i} value={new Date().getFullYear() - i}>
                      {new Date().getFullYear() - i}
                    </option>
                  ))}
                </select>
              </div>
              <input type="hidden" id="dateOfBirth" value={combinedDate} />
            </div> */}
            <div className="mb-3">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth:
              </label>
              <DatePicker
                selected={dateOfBirth}
                onChange={(date) => setDateOfBirth(date)}
                className="form-control"
                dateFormat="yyyy-MM-dd"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select" // Allows dropdown for year and month
                placeholderText="Select your date of birth"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="interests" className="form-label">
                Interests (comma-separated):
              </label>
              <input
                type="text"
                className="form-control"
                id="interests"
                placeholder="e.g., music, coding, reading"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary me-2"
                data-bs-dismiss="offcanvas"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : null}
                {isLoading ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
