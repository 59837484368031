import React, { useState } from "react";

const Refferral = () => {
  return (
    <div>
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h6 className="title">Refer and earn</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content space-top p-b60">
        <div className="container pt-0">
          <div className="profile-area style-2">
            <div className="main-profile flex justify-content-center">
              <div className="profile-detail text-center">
                <h4 className="name">Copy your referal link below</h4>
                <a href="#" className="text-decoration-none chat-bubble">
                  Invite friends and earn reward
                </a>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-6">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media">
                      <div>
                        <span className="font-22">Total Referral:</span>
                        <h6 className="mb-0 title font-15">0</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-35 m-r10">
                        <img
                          src="../assets/images/w3badoo/icon/coin.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="font-18">Referral earning:</span>
                        <h6 className="mb-0 title font-15">₹1550</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dz-subscribe-area">
              <div className="subscribe-area-top">
                <div className="swiper subscription-swiper2">
                  <div className="swiper-wrapper mb-3">
                    <div className="swiper-slide" data-card="premium-plus">
                      <div className="subscribe-box style-2 premium-plus">
                        <h5 className="title">
                          <img
                            src="../assets/images/w3badoo/icon/fire.png"
                            alt=""
                          />
                          Join our Premuium and earn daily reward
                        </h5>
                        <p className="desc">
                          Maximize your watching with all the benefits of
                          Premium, plus extra features included
                        </p>
                        <div className="plan-price">
                          <a
                            href="/subscribe"
                            className="text-decoration-none chat-bubble"
                          >
                            Upgrade From $12
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span>Referral link: </span>{" "}
            <a href="#" className="text-decoration-none chat-bubble">
              Copy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Refferral;
