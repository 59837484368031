import React, { useState, useEffect, useRef } from "react";
import { getBalance, getReels } from "../../api/apiService";
import CoinClaim from "./CoinClaim";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import LazyLoad from "react-lazy-load";

const Reels = () => {
  const [reelsData, setReelsData] = useState([]);
  const [isMuted, setIsMuted] = useState(true); // Global mute state
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [hasMore, setHasMore] = useState(true); // To track if more videos are available
  const [starCoinBalance, setStarCoinBalance] = useState(0);
  const [usdtEquivalent, setUsdtEquivalent] = useState(0);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [rewardSplash, setRewardSplash] = useState(null);
  const [nextAppearanceTime, setNextAppearanceTime] = useState(null);
  const [loading, setLoading] = useState(true);

  const containerRef = useRef(null);
  const observerRef = useRef(null);

  // Fetch reels from the endpoint
  const fetchReels = async (page = 1) => {
    setLoading(true);
    try {
      const data = await getReels(page);

      if (data.reels.length === 0) {
        setHasMore(false);
        return;
      }

      const formattedReels = data.reels.map((reel) => ({
        id: reel._id,
        videoSrc: reel.videoUrl,
        avatar: reel.avatar || "/assets/images/avatar/2.jpg",
        username: `@${reel.userName}`,
        likes: reel.likes,
        isLiked: false,
        description: reel.description,
        comments: reel.comments.map((comment) => ({
          userName: comment.userName,
          comment: comment.comment,
          avatar: comment.avatar,
        })),
      }));

      setReelsData((prevReels) => [...prevReels, ...formattedReels]);
    } catch (error) {
      console.error("Failed to fetch reels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReels(); // Initial fetch
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      let balance;
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const status = userObject.demo;
        const response = await getBalance();
        if (status) {
          balance = response.star.demoStarBalance;
        } else {
          balance = response.star.balance;
        }
        console.log("User star balance", balance);
        // Fetch StarCoin balance from the API
        setStarCoinBalance(balance);

        // Perform manual conversion
        const usdt = balance / 10;
        setUsdtEquivalent(usdt);
      } catch (error) {
        console.error("Error fetching StarCoin balance:", error);
      }
    };

    fetchBalance();
  }, []);

  const handleScroll = () => {
    if (!containerRef.current || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollTop + clientHeight >= scrollHeight - 600) {
      fetchReels(currentPage + 1);
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Handle video visibility and playback
  useEffect(() => {
    const handleVideoVisibility = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.dataset.index);
          setActiveVideoIndex(index);
        }
      });
    };

    const observerOptions = {
      threshold: 0.8,
    };

    const observer = new IntersectionObserver(
      handleVideoVisibility,
      observerOptions
    );

    const videoElements = document.querySelectorAll(".reel video");
    videoElements.forEach((video) => observer.observe(video));

    return () => {
      if (observer) observer.disconnect();
    };
  }, [reelsData]);

  useEffect(() => {
    const videoElements = document.querySelectorAll(".reel video");

    videoElements.forEach((video, idx) => {
      if (idx === activeVideoIndex) {
        video
          .play()
          .then(() => console.log(`Playing video at index ${idx}`))
          .catch((error) => console.error("Error playing video:", error));
      } else {
        video.pause();
        video.currentTime = 0; // Reset video playback position
      }
    });
  }, [activeVideoIndex]);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  return (
    <div
      ref={containerRef}
      className="page-content"
      style={{
        height: "100vh",
        overflowY: "scroll",
        scrollSnapType: "y mandatory",
        backgroundColor: "#000",
      }}
      onScroll={handleScroll}
    >
      <div
        className="balance-container"
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          backgroundColor: "#1a1a1a",
          borderRadius: "8px",
          padding: "5px 10px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          color: "#fff",
          zIndex: 1000,
          fontSize: "14px",
        }}
      >
        <img
          src="/assets/images/w3badoo/icon/coin.png"
          alt="Star Coin Icon"
          style={{
            width: "16px",
            height: "16px",
            marginRight: "6px",
          }}
        />
        <span>{starCoinBalance}</span>
        <span
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          ~${usdtEquivalent.toFixed(2)}
          <img
            src="/assets/images/w3badoo/icon/coin.png"
            alt="USDT Icon"
            style={{
              width: "14px",
              height: "14px",
              marginLeft: "5px",
            }}
          />
        </span>
      </div>
      {/* Loading Spinner */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              border: "5px solid rgba(255, 255, 255, 0.1)",
              borderTop: "5px solid #fff",
              borderRadius: "50%",
              animation: "spin 1s linear infinite",
            }}
          ></div>
          <style>
            {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
          </style>
        </div>
      )}

      {/* Global Volume Control */}
      <div
        className="volume-control"
        style={{
          position: "fixed",
          bottom: "120px",
          right: "50px",
          zIndex: 1000,
          background: "#000",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={toggleMute}
      >
        <i
          className={`fa ${isMuted ? "fa-volume-mute" : "fa-volume-up"}`}
          style={{ color: "#fff", fontSize: "20px" }}
        ></i>
      </div>

      {/* Reels List */}
      {reelsData.map((reel, index) => (
        <LazyLoad
          key={`${reel.id}-${index}`}
          offsetVertical={300} // Preload videos before they come into view
          debounce={false}
          once
        >
          <Reel
            reel={reel}
            index={index}
            activeVideoIndex={activeVideoIndex}
            isMuted={isMuted}
          />
        </LazyLoad>
      ))}
    </div>
  );
};

const Reel = ({
  reel,
  index,
  activeVideoIndex,
  isMuted,
  setActiveVideoIndex,
}) => {
  const videoRef = useRef(null);
  const [isVideoLoading, setIsVideoLoading] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    if (video) {
      if (index === activeVideoIndex) {
        // Play the active video
        video
          .play()
          .then(() => {
            console.log(`Video ${index} is playing`);
          })
          .catch((error) => {
            console.error(`Error playing video ${index}:`, error);
          });
      } else {
        // Pause the non-active videos
        video.pause();
        video.currentTime = 0; // Reset to the beginning for seamless experience
      }
    }
  }, [index, activeVideoIndex]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted; // Sync the muted state
    }
  }, [isMuted]);

  return (
    <>
      <div
        className="reel"
        style={{
          height: "100vh",
          scrollSnapAlign: "start",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
        }}
      >
        <div className="inner-content" style={{ paddingBottom: "40px" }}>
          <div className="reel-section">
            <div className="user-item">
              <a href="javascript:void(0);">
                <div className="media media-40 rounded-circle">
                  <img src={reel.avatar} alt={reel.username} />
                </div>
              </a>
              <div className="ms-2">
                <a href="javascript:void(0);" className="text-decoration-none">
                  <span className="text-white">{reel.username}</span>
                </a>
                <a href="javascript:void(0);" className="follow-btn ms-3">
                  UNFOLLOW
                </a>
                <p style={{ margin: "5px 0" }}>{reel.description}</p>
              </div>
            </div>

            <div className="reel-actions">
              <a
                href="javascript:void(0);"
                className="r-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleLike(index);
                }}
              >
                <div className="like-button font-24">
                  <i
                    className={`fa-regular ${
                      reel.isLiked ? "fa-heart liked" : "fa-heart"
                    }`}
                  ></i>
                </div>
                <span>{reel.likes}</span>
              </a>
              <a
                href="javascript:void(0);"
                onClick={() => setShowComments(!showComments)}
                className="r-btn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom1"
                aria-controls="offcanvasBottom"
              >
                <div className="like-button font-24">
                  <i className="fa-regular fa-comment"></i>
                </div>
                <span>{reel.comments.length}</span>
              </a>
              <a href="javascript:void(0);" className="r-btn">
                <div className="like-button font-24">
                  <i className="fa fa-share ms-auto"></i>
                </div>
              </a>
              <a
                href="javascript:void(0);"
                className="r-btn pb-0"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom2"
                aria-controls="offcanvasBottom"
              >
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </a>
            </div>
          </div>
        </div>
        {/* Video Section */}
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <video
            ref={videoRef}
            src={reel.videoSrc}
            muted={isMuted}
            preload="auto"
            loop
            data-index={index}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            onCanPlay={() => setIsVideoLoading(false)}
            onClick={() => setActiveVideoIndex(index)}
          />

          {/* Mute/Unmute Toggle Overlay */}
          {/* <div
            onClick={toggleMute}
            style={{
              position: "absolute",
              bottom: "10%",
              right: "10%",
              width: "40px",
              height: "40px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <i
              className={`fa ${isMuted ? "fa-volume-mute" : "fa-volume-up"}`}
              style={{ color: "#fff", fontSize: "20px" }}
            ></i>
          </div> */}
        </div>
      </div>

      {/* <div
        className="offcanvas offcanvas-bottom reel-canvas"
        tabIndex="-1"
        id="offcanvasBottom1"
        style={{
          height: "70vh",
          overflowY: "auto",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            border: "none",
            backgroundColor: "transparent",
            padding: "8px",
          }}
          onFocus={(e) => {
            e.target.style.outline = "2px solid #FF50A2";
          }}
          onBlur={(e) => {
            e.target.style.outline = "none";
          }}
        ></button>

        <div
          className="offcanvas-header"
          style={{
            borderBottom: "1px solid #ddd",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5 className="mb-0" style={{ fontWeight: "bold", color: "#333" }}>
            Comments
          </h5>
        </div>

        <div className="offcanvas-body container pb-0">
          <form
            style={{
              display: "flex",
              alignItems: "center",
              padding: "16px",
              borderBottom: "1px solid #ddd",
            }}
          >
            <input
              type="text"
              className="form-control border-0"
              placeholder="Write a comment..."
              style={{
                flex: 1,
                backgroundColor: "#f1f1f1",
                borderRadius: "8px",
                padding: "10px 12px",
              }}
            />
            <button
              type="button"
              className="btn btn-primary ms-2"
              style={{
                backgroundColor: "#FF50A2",
                border: "none",
                borderRadius: "8px",
              }}
            >
              Post
            </button>
          </form>

          <div className="canvas-height mt-4 dz-scroll">
            {reelsData[currentReel].comments.map((comment, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "16px",
                  paddingBottom: "16px",
                  borderBottom: "1px solid #eee",
                }}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    marginRight: "12px",
                  }}
                >
                  <img
                    src={comment.avartar}
                    alt="User Avatar"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div>
                  <strong style={{ color: "#333", fontSize: "14px" }}>
                    {comment.userName}
                  </strong>
                  <p
                    style={{
                      margin: "4px 0 0",
                      color: "#555",
                      fontSize: "14px",
                    }}
                  >
                    {comment.comment}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-bottom reel-canvas"
        tabIndex="-1"
        id="offcanvasBottom2"
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            border: "none",
            backgroundColor: "transparent",
            padding: "8px",
          }}
          onFocus={(e) => {
            e.target.style.outline = "2px solid #FF50A2";
          }}
          onBlur={(e) => {
            e.target.style.outline = "none";
          }}
        ></button>
        <div
          className="offcanvas-header"
          style={{
            borderBottom: "1px solid #ddd",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h5 className="mb-0" style={{ fontWeight: "bold", color: "#333" }}>
            Others
          </h5>
        </div>
        <div className="offcanvas-body">
          <ul className="features-list">
            <li>
              <a href="javascript:void(0);" className="text-decoration-none">
                <div className="dz-icon">
                  <i className="icon feather icon-bookmark"></i>
                </div>
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" className="text-decoration-none">
                <div className="dz-icon">
                  <i className="icon feather icon-sliders"></i>
                </div>
                <span>Report</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" className="text-decoration-none">
                <div className="dz-icon">
                  <i className="icon feather icon-eye-off"></i>
                </div>
                <span>Hide</span>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default Reels;
