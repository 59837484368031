import { Link, useLocation } from "react-router-dom";
import { login } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await login(email, password);
      localStorage.setItem("token", data.token);
      localStorage.setItem("referral", data.referralLink);
      localStorage.setItem("user", JSON.stringify(data.existingUser));
      const redirectTo = location.state?.from?.pathname || "/";
      navigate(redirectTo);
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Login failed! Please try again..."
      );
    }
  };

  return (
    <div>
      <div
        className="page-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f9f9f9",
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: "400px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "24px",
          }}
        >
          <div className="account-area style-2">
            <div
              className="section-head ps-0"
              style={{
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              <h2 style={{ fontWeight: "bold", color: "#333" }}>Login</h2>
            </div>
            <form onSubmit={handleLogin}>
              <div
                className="bg-white input-group input-group-icon input-mini"
                style={{
                  marginBottom: "16px",
                }}
              >
                <label
                  style={{
                    display: "block",
                    fontWeight: "600",
                    marginBottom: "8px",
                    color: "#555",
                  }}
                >
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                  style={{
                    width: "100%",
                    padding: "10px",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div
                className="bg-white input-group input-group-icon input-mini"
                style={{
                  marginBottom: "16px",
                  position: "relative",
                }}
              >
                <label
                  style={{
                    display: "block",
                    fontWeight: "600",
                    marginBottom: "8px",
                    color: "#555",
                  }}
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter your password"
                  style={{
                    width: "100%",
                    padding: "10px",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onMouseDown={(e) => e.preventDefault()}
                  onClick={() => setShowPassword((prev) => !prev)}
                  style={{
                    position: "absolute",
                    top: "80%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                    zIndex: 10,
                  }}
                >
                  {showPassword ? "🙈" : "👁️"}
                </button>
              </div>

              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "#8850f7",
                  border: "none",
                  fontWeight: "600",
                  color: "#fff",
                }}
              >
                {loading ? "Loading..." : "Login"}
              </button>
            </form>
            {errorMessage && (
              <p
                style={{
                  marginTop: "16px",
                  fontSize: "14px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {errorMessage}
              </p>
            )}
            <p
              style={{
                marginTop: "16px",
                fontSize: "14px",
                textAlign: "center",
                color: "#555",
              }}
            >
              Don’t have an account yet?{" "}
              <a
                href="/signup"
                style={{
                  color: "#8850f7",
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Register
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
