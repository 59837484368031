import React, { useState } from "react";

const MyWallet = () => {
  return (
    <div>
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h6 className="title">Wallet</h6>
            </div>
            <div className="mid-content"></div>
            <div className="right-content"></div>
          </div>
        </div>
      </div>
      <div className="dark-overlay"></div>
      <div className="page-content space-top p-b60">
        <div className="container pt-2">
          <div className="profile-area style-2">
            <div className="dz-subscribe-area">
              <div className="subscribe-area-top">
                <div className="swiper subscription-swiper2">
                  <div className="swiper-wrapper mb-3">
                    <div className="swiper-slide" data-card="premium-plus">
                      <div className="subscribe-box style-2 premium-plus">
                        <span
                          className="title"
                          style={{
                            display: "block",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <img
                            src="/assets/images/w3badoo/icon/fire.png"
                            alt=""
                          />
                          1,125.00
                          <span
                            className="badge badge-primary"
                            style={{
                              background: "blueviolet",
                              marginLeft: "5px",
                            }}
                          >
                            Demo
                          </span>
                        </span>
                        <span
                          style={{
                            fontSize: "small",
                            marginTop: "-15px",
                            padding: 0,
                            color: "#fff",
                            display: "block",
                          }}
                        >
                          ~
                          <img
                            style={{
                              width: "20px",
                            }}
                            src="/assets/images/w3badoo/icon/coin.png"
                            alt=""
                          />
                          1,125.00
                        </span>

                        <div className="plan-price">
                          {" "}
                          <a href="/subscribe" className="btn">
                            Upgrade
                          </a>
                          {/* <button type="button" class="btn btn-sm">
                            <a
                              href=""
                              style={{
                                color: "#000",
                              }}
                              className="text-decoration-none badge badge-primary"
                            >
                              Withdraw
                            </a>{" "}
                            /{" "}
                            <a
                              href=""
                              style={{
                                color: "#000",
                              }}
                              className="text-decoration-none badge badge-primary"
                            >
                              Deposit
                            </a>
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media row p-l20">
                      <h2 className="font-24 text-center">My Earnings</h2>
                      <div className="row ">
                        <div className="col-4">
                          <div>
                            <span className="font-15">Lkes</span>
                            <h6 className="mb-0 font-15">$150</h6>
                          </div>
                        </div>

                        <div className="col-4">
                          <div>
                            <span className="font-15">Views</span>
                            <h6 className="mb-0 font-15">$10</h6>
                          </div>
                        </div>

                        <div className="col-4">
                          <div>
                            <span className="font-15">Gift</span>
                            <h6 className="mb-0 font-15">$50</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-4">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-25 m-r10">
                        <img
                          src="/assets/images/w3badoo/icon/coin.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="title font-15">USDT</span>
                        <h6 className="mb-0 title font-15">$1550</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-25 m-r10">
                        <img
                          src="/assets/images/w3badoo/icon/coin.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="title font-15">Chat</span>
                        <h6 className="mb-0 title font-15">$1550</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media">
                      <div className="media-25 m-r10">
                        <img
                          src="/assets/images/w3badoo/icon/coin.png"
                          alt=""
                        />
                      </div>
                      <div>
                        <span className="title font-15">Gift</span>
                        <h6 className="mb-0 title font-15">$1550</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <div className="card card-bx">
                  <div className="card-body">
                    <div className="media row p-l20">
                      <div className="row ">
                        <div className="col-4">
                          <a href="#" class="btn btn-primary rounded-xl">
                            transfer
                          </a>
                        </div>

                        <div className="col-4">
                          <div>
                            <span className="font-15">Views</span>
                            <h6 className="mb-0 font-15">$10</h6>
                          </div>
                        </div>

                        <div className="col-4">
                          <div>
                            <span className="font-15">Gift</span>
                            <h6 className="mb-0 font-15">$50</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row g-2">
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom1"
                      aria-controls="offcanvasBottom"
                      className="text-decoration-none"
                    >
                      <div className="card-icon">
                        <i className="fa fa-arrow-right"></i>
                      </div>
                      <div className="card-content">
                        <p>Convert</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <a
                      href="javascript:void(0);"
                      className="r-btn"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasWithdraw"
                      aria-controls="offcanvasBottom"
                    >
                      <div className="card-icon">
                        <i className="fa fa-wallet"></i>
                      </div>
                      <div className="card-content">
                        <p>Withdraw</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card style-2">
                  <div className="card-body">
                    <a
                      href="javascript:void(0);"
                      className="r-btn"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasTransfer"
                      aria-controls="offcanvasBottom"
                    >
                      <div className="card-icon">
                        <i className="fa fa-exchange"></i>
                      </div>
                      <div className="card-content">
                        <p>Transfer</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-2 ">
              <div className="title-bar">
                <h6 className="title">Transaction History</h6>
              </div>
              <ul className="message-list">
                <li>
                  <a href="#" className="text-decoration-none">
                    <div className="media-content">
                      <div>
                        <h6 className="name">$ 20000</h6>
                        <p className="last-msg">deposit</p>
                      </div>
                      <div className="right-content">
                        <span className="date">2m ago</span>
                        <div className="seen-btn active dz-flex-box btn-success">
                          <i className="icon feather icon-check"></i>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#" className="text-decoration-none">
                    <div className="media-content">
                      <div>
                        <h6 className="name">$ 40000</h6>
                        <p className="last-msg">deposit</p>
                      </div>
                      <div className="right-content">
                        <span className="date">2m ago</span>
                        <div className="seen-btn active dz-flex-box btn-success">
                          <i className="icon feather icon-check"></i>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Conversion canvas */}
      <div
        className="offcanvas offcanvas-bottom reel-canvas"
        tabIndex="-1"
        id="offcanvasBottom1"
        style={{
          height: "70vh",
          overflowY: "auto",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            border: "none",
            backgroundColor: "transparent",
            padding: "8px",
          }}
          onFocus={(e) => {
            e.target.style.outline = "2px solid #FF50A2";
          }}
          onBlur={(e) => {
            e.target.style.outline = "none";
          }}
        ></button>

        <div>
          <div
            className="offcanvas-header"
            style={{
              borderBottom: "1px solid #ddd",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <img
              src="/assets/images/w3badoo/icon/coin.png"
              alt="Top Image"
              style={{
                width: "80px",
                objectFit: "cover",
                borderRadius: "8px",
                marginBottom: "12px",
              }}
            />
            <h5
              className="mb-0"
              style={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
            >
              CONVERT
            </h5>
            <p
              style={{
                fontSize: "14px",
                color: "#666",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              Convert your flower to STARFACECOIN
            </p>
            <small
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                color: "#999",
                textAlign: "center",
              }}
            >
              Available Balance: $
            </small>
          </div>

          <div className="offcanvas-body container pb-0">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #ddd",
              }}
            >
              <input
                type="number"
                className="form-control border-0"
                placeholder="Enter Amount to convert"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              />
              <small
                style={{
                  marginBottom: "8px",
                  fontSize: "18px",
                  color: "#999",
                  textAlign: "center",
                }}
              >
                You receive: $
              </small>
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#FF50A2",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                Convert
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Withdrawal canvas */}
      <div
        className="offcanvas offcanvas-bottom withdraw-canvas"
        tabIndex="-1"
        id="offcanvasWithdraw"
        style={{
          height: "70vh",
          overflowY: "auto",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            border: "none",
            backgroundColor: "transparent",
            padding: "8px",
          }}
        ></button>

        <div>
          <div
            className="offcanvas-header"
            style={{
              borderBottom: "1px solid #ddd",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/assets/images/w3badoo/icon/coin.png"
              alt="Coin Symbol"
              style={{
                width: "80px",
                objectFit: "cover",
                borderRadius: "8px",
                marginBottom: "12px",
              }}
            />
            <h5
              className="mb-0"
              style={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
            >
              Withdraw
            </h5>
            <small
              style={{
                marginBottom: "8px",
                fontSize: "18px",
                color: "#999",
                textAlign: "center",
              }}
            >
              Available Balance: $
            </small>
          </div>

          <div className="offcanvas-body container pb-0">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <input
                type="text"
                className="form-control border-0"
                placeholder="Recipient Address"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              />
              <input
                type="number"
                className="form-control border-0"
                placeholder="Enter Amount"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              />
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#FF50A2",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                Withdraw
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Transfer canvas */}
      <div
        className="offcanvas offcanvas-bottom transfer-canvas"
        tabIndex="-1"
        id="offcanvasTransfer"
        style={{
          height: "70vh",
          overflowY: "auto",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          backgroundColor: "#f8f9fa",
        }}
      >
        <button
          type="button"
          className="btn drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          style={{
            position: "absolute",
            left: "16px",
            top: "16px",
            border: "none",
            backgroundColor: "transparent",
            padding: "8px",
          }}
        ></button>

        <div>
          <div
            className="offcanvas-header"
            style={{
              borderBottom: "1px solid #ddd",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/assets/images/w3badoo/icon/coin.png"
              alt="Wallet Icon"
              style={{
                width: "80px",
                objectFit: "cover",
                borderRadius: "8px",
                marginBottom: "12px",
              }}
            />
            <h5
              className="mb-0"
              style={{ fontWeight: "bold", color: "#333", textAlign: "center" }}
            >
              Transfer
            </h5>
          </div>

          <div className="offcanvas-body container pb-0">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <select
                className="form-select"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              >
                <option>Select Wallet to Transfer From</option>
                <option>Wallet 1</option>
                <option>Wallet 2</option>
              </select>
              <select
                className="form-select"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              >
                <option>Select Wallet to Transfer To</option>
                <option>Wallet 1</option>
                <option>Wallet 2</option>
              </select>
              <input
                type="number"
                className="form-control border-0"
                placeholder="Enter Amount"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  borderRadius: "8px",
                  padding: "10px 12px",
                  marginBottom: "8px",
                }}
              />
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#FF50A2",
                  border: "none",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  width: "100%",
                }}
              >
                Transfer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWallet;
