import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef } from "react";

const GetStarted = () => {
  return (
    <>
      <div className="content-body">
        <div
          className="welcome-area"
          style={{
            backgroundImage: "url('/assets/images/w3badoo/background/bg1.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="welcome-inner flex-column">
            <div className="logo-area">
              <img className="logo" src="/assets/loggg.png" alt="" />
              <p className="para-title">
                Join us with other millions of people to watch reels and find
                your best matches.
              </p>
            </div>
            <div className="social-area">
              <a
                href="#"
                className="btn btn-icon icon-start btn-tp h-100 w-100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "5px",
                  borderRadius: "8px",
                  fontWeight: "bold",
                }}
              >
                <img
                  src="/assets/images/social-icon/google.svg"
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "10px",
                  }}
                />
                <span className="p-20">Sign in with Google</span>
              </a>
              <a
                href="/login"
                className="btn btn-icon-outline btn-primary icon-start h-100 w-100"
              >
                {/* <i className="fa-brands fa-facebook-f"></i> */}
                <i className="fa-solid fa-envelope"></i>
                <span>Sign in with Email</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetStarted;
