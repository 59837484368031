import { Outlet, useLocation } from "react-router-dom";

import Footer from "../components/Footer";

const RootLayout = () => {
  const location = useLocation();

  console.log(location);

  return (
    <>
      <main>
        <Outlet />
      </main>
      {location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === "/get-started" ? undefined : (
        <Footer />
      )}
    </>
  );
};

export default RootLayout;
