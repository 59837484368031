import React, { useState, useEffect, useRef } from "react";
import { uploadImage } from "../../api/apiService";

const Connect = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDesc, setVideoDesc] = useState("");
  const [activeTab, setActiveTab] = useState("photos");
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fileInputRef = useRef(null);

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    if (activeTab === "photos") {
      const imageFiles = files.filter((file) => file.type.startsWith("image/"));

      if (imageFiles.length + selectedFiles.length > 5) {
        setErrorMessage("You can only upload up to 5 images at a time.");
        return;
      }

      setSelectedFiles((prev) => [...prev, ...imageFiles]);
      setErrorMessage("");
    } else if (activeTab === "videos") {
      const videoFiles = files.filter((file) => file.type.startsWith("video/"));
      if (videoFiles.length > 0) {
        setVideoFile(videoFiles[0]);
        setErrorMessage("");
      } else {
        setErrorMessage("Please select a valid video file.");
      }
    }
  };

  const handleUpload = async () => {
    // Check for selected files
    if (activeTab === "photos" && selectedFiles.length === 0) {
      setErrorMessage("No files selected for upload.");
      return;
    }

    if (activeTab === "videos" && videoFile.length !== 1) {
      setErrorMessage("Please select exactly one video for upload.");
      return;
    }

    setIsUploading(true);

    try {
      let response;

      if (activeTab === "photos") {
        // Call photo upload endpoint for multiple photos
        response = await uploadImage(selectedFiles, description);
      } else if (activeTab === "videos") {
        // Call video upload endpoint for a single video
        response = await uploadVideo(videoFile, videoTitle, videoDesc);
      }

      alert("Upload successful!");
      console.log(response);

      setSelectedFiles([]);
      setDescription("");
      setVideoFile("");
      setVideoTitle("");
      setVideoDesc("");
    } catch (error) {
      setErrorMessage("Failed to upload files. Try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handlePhotoClick = () => {
    setActiveTab("photos");
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleVideoClick = () => {
    setActiveTab("videos");
    if (fileInputRef.current) fileInputRef.current.click();
  };

  useEffect(() => {
    selectedFiles.forEach((file) => {
      if (file.preview) URL.revokeObjectURL(file.preview);
    });
  }, [selectedFiles]);

  return (
    <div>
      <header className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a href="/" className="text-decoration-none back-btn">
                <i className="icon feather icon-chevron-left"></i>
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="content-body">
        <div className="welcome-area bg-image">
          <div className="welcome-inner">
            <div className="dz-media">
              <a
                href="javascript:void(0);"
                className="r-btn"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom2"
                aria-controls="offcanvasBottom2"
              >
                <label className="file-upload-label">
                  <i className="fa-solid fa-upload"></i> Upload Media
                </label>
              </a>
            </div>
          </div>
        </div>

        <div
          className="offcanvas offcanvas-bottom reel-canvas"
          tabIndex="-1"
          id="offcanvasBottom2"
        >
          <button
            type="button"
            className="btn drage-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="offcanvas-body">
            <ul className="features-list">
              <li>
                <a
                  href="javascript:void(0);"
                  className="text-decoration-none"
                  onClick={handlePhotoClick}
                >
                  <div className="dz-icon">
                    <i className="fa-solid fa-image"></i>
                  </div>
                  <span>Photo</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="text-decoration-none"
                  onClick={handleVideoClick}
                >
                  <div className="dz-icon">
                    <i className="fa-solid fa-video"></i>
                  </div>
                  <span>Video</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="text-decoration-none">
                  <div className="dz-icon">
                    <div className="go-live-container">
                      <i className="fa-solid fa-broadcast-tower go-live-icon"></i>
                    </div>
                  </div>
                  <span>Go Live</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept={
          activeTab === "videos"
            ? "image/*"
            : activeTab === "photos"
            ? "video/*"
            : ""
        }
        multiple
      />

      {selectedFiles.length > 0 ? (
        <div
          style={{
            width: "100%",
            padding: "20px 0",
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            backgroundColor: "#fff",
            overflowY: "scroll",
            zIndex: 1000,
          }}
          className="page-content p-b70"
        >
          <div className="container" style={{ maxWidth: "1200px" }}>
            <div className="account-area">
              {/* Back Button */}
              <a
                href="javascript:void(0);"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
                onClick={() => setSelectedFiles([])}
              >
                <i
                  style={{
                    fontSize: "24px",
                    color: "#888",
                    marginRight: "8px",
                  }}
                  className="icon feather icon-chevron-left"
                ></i>
                <span style={{ fontSize: "16px", color: "#555" }}>Back</span>
              </a>

              {/* Section Header */}
              <h3 style={{ fontSize: "24px", marginBottom: "20px" }}>
                Add More Pics
              </h3>

              {/* Media Preview */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {selectedFiles.map((file, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      width: "calc(33.333% - 20px)",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                    {/* Remove Button */}
                    <button
                      onClick={() =>
                        setSelectedFiles((prev) =>
                          prev.filter((_, i) => i !== index)
                        )
                      }
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        border: "none",
                        borderRadius: "50%",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <i className="icon feather icon-x"></i>
                    </button>
                  </div>
                ))}

                {/* Add More Media Icon */}
                <div
                  onClick={() =>
                    fileInputRef.current && fileInputRef.current.click()
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "2px dashed #ddd",
                    borderRadius: "8px",
                    width: "calc(33.333% - 20px)",
                    height: "100px",
                    cursor: "pointer",
                  }}
                >
                  <i
                    style={{ fontSize: "24px", color: "#888" }}
                    className="icon feather icon-plus"
                  ></i>
                  <span style={{ fontSize: "14px", color: "#888" }}>
                    Add More
                  </span>
                </div>
              </div>

              {/* Description Input */}
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add a description for your"
                rows="3"
                style={{
                  width: "100%",
                  padding: "10px",
                  marginTop: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  fontSize: "16px",
                }}
              ></textarea>

              {/* Upload Button */}

              <div class="footer bg-white">
                <div class="container">
                  <a
                    onClick={handleUpload}
                    disabled={isUploading}
                    class="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
                    style={{
                      cursor: isUploading ? "not-allowed" : "pointer",
                    }}
                  >
                    {isUploading ? "Uploading..." : `Upload ${activeTab}`}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="offcanvas offcanvas-bottom reel-canvas"
          tabIndex="-1"
          id="offcanvasBottom2"
        >
          <button
            type="button"
            className="btn drage-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <div className="offcanvas-body">
            <ul className="features-list">
              <li>
                <a
                  href="javascript:void(0);"
                  className="text-decoration-none"
                  onClick={handlePhotoClick}
                >
                  <div className="dz-icon">
                    <i className="fa-solid fa-image"></i>
                  </div>
                  <span>Photo</span>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="text-decoration-none"
                  onClick={handleVideoClick}
                >
                  <div className="dz-icon">
                    <i className="fa-solid fa-video"></i>
                  </div>
                  <span>Video</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="text-decoration-none">
                  <div className="dz-icon">
                    <div className="go-live-container">
                      <i className="fa-solid fa-broadcast-tower go-live-icon"></i>
                    </div>
                  </div>
                  <span>Go Live</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default Connect;
