import axios from "axios";

// Base configuration for all API requests
const apiClient = axios.create({
  baseURL: "https://starfaceapi.site/api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor for adding the token if available
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Request OTP endpoint
export const requestOTP = async (email, referralCode) => {
  const payload = { email };
  if (referralCode) {
    payload.referralCode = referralCode;
  }
  const response = await apiClient.post("/auths/request-otp", payload);
  return response.data;
};

// Resend OTP endpoint
export const resendOTP = async (userId, email) => {
  const response = await apiClient.post("/auths/resendotp", {
    userId,
    email,
  });
  return response.data;
};

// Verify OTP endpoint
export const verifyOTP = async (userId, otp) => {
  const response = await apiClient.post("/auths/verify-otp", {
    userId,
    otp,
  });
  return response.data;
};

// Resend OTP endpoint
export const setUserPassword = async (email, password) => {
  const response = await apiClient.post("/auths/set-password", {
    email,
    password,
  });
  return response.data;
};

// Login endpoint
export const login = async (email, password) => {
  const response = await apiClient.post("/auths/signin", { email, password });
  return response.data; // Return response data
};

// Fetch user profile
export const fetchUserProfile = async () => {
  const response = await apiClient.get("/user/profile");
  return response.data;
};

// Transfer endpoint
export const transferFunds = async (fromWallet, toWallet, amount) => {
  const response = await apiClient.post("/wallets/transfer", {
    fromWallet,
    toWallet,
    amount,
  });
  return response.data;
};

export const redeemStar = async () => {
  try {
    const response = await apiClient.get("/transaction/redeem-stars");
    return response.data;
  } catch (error) {
    console.error(
      "Error redeeming stars:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getBalance = async () => {
  try {
    const response = await apiClient.get("/earn/wallet");
    return response.data;
  } catch (error) {
    console.error(
      "Error getting balance",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const updateUserProfile = async (
  profilePicture,
  bio,
  interests,
  dateOfBirth
) => {
  try {
    const formData = new FormData();

    // Add profile picture if provided
    if (profilePicture) {
      formData.append("profilePicture", profilePicture);
    }

    if (dateOfBirth !== undefined && dateOfBirth !== null) {
      formData.append("dateOfBirth", dateOfBirth);
    }

    // Add bio if provided
    if (bio !== undefined && bio !== null) {
      formData.append("bio", bio);
    }

    // Add interests as individual items (if backend supports this format)
    if (Array.isArray(interests) && interests.length > 0) {
      interests.forEach((interest) => formData.append("interests[]", interest));
    }

    const response = await apiClient.put("/user/profile-update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error updating user profile:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const uploadImage = async (images, description) => {
  try {
    const formData = new FormData();

    // Append each file using "images" as the key
    images.forEach((file) => formData.append("images", file));

    // Add description if provided
    if (description) {
      formData.append("description", description);
    }

    const response = await apiClient.post("/images/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error uploading images:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const uploadVideo = async (file, title, description) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    if (title) formData.append("title", title);
    if (description) formData.append("description", description);

    const response = await apiClient.post("/reel/post-reels", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error uploading video:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const getReels = async (page) => {
  try {
    const response = await apiClient.get(`/reel/get-reels?page=${page}`);
    return response.data;
  } catch (error) {
    console.error("Error getting Reels", error.response?.data || error.message);
    throw error;
  }
};

export const getImages = async () => {
  try {
    const response = await apiClient.get("/images");

    // Extract and return only the images
    const images = response.data.data.flatMap((item) =>
      item.images.map((image) => ({
        ...image,
        desc: item.desc,
        likes: item.likes,
        uploadedAt: item.uploadedAt,
      }))
    );

    return images;
  } catch (error) {
    console.error(
      "Error getting images",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const GetUsers = async () => {
  try {
    const response = await apiClient.get("/connect");
    return response.data;
  } catch (error) {
    console.error("Error getting users", error.response?.data || error.message);
    throw error;
  }
};
