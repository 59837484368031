import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#contact") {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const [dateType, setDateType] = useState(false);
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [branch, setBranch] = useState("");
  const [message, setMessage] = useState("");
  const [dtat, setDtat] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the form data using EmailJS
    emailjs
      .send(
        "service_x020y9a",
        "template_udxpzbr",
        {
          from_name: name,
          from_email: email,
          phone: phone,
          message: message,
        },
        "BKf6FheImnAPRtapX"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success("Form submitted successfully!");
        // Handle successful submission (e.g., show a success message)
        setName("");
        setEmail("");
        setMessage("");
        setPhone("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        // Handle error (e.g., show an error message)
        toast.success("Form Failed To Submit!");
      });
  };

  return (
    <section>
      <div className="py-56 bg-pages-heroBg bg-no-repeat bg-cover bg-center" />
      <div className="pt-12 pb-14 px-6 sm:px-12 md:px-20 lg:px-16 xl:px-28">
        <div className="flex flex-col items-center mb-12">
          <h2
            className="mb-2 text-center text-4xl font-bold uppercase md:text-5xl"
            id="contact"
          >
            Contact us
          </h2>
          <div className="w-10 h-[3px] bg-[#bd0103]" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
