import React from "react";
import ReactDOM from "react-dom/client";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import "./assets/js/dz.carousel";
import "./assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./assets/vendor/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
import "./assets/js/custom";
import "./assets/vendor/swiper/swiper-bundle.min.js";

{
  /* <script src="/assets/js/jquery.js"></script>
<script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="/assets/vendor/bootstrap-select/dist/js/bootstrap-select.min.js"></script>
<script src="/assets/vendor/swiper/swiper-bundle.min.js"></script><!-- Swiper -->
<script src="/assets/js/dz.carousel.js"></script><!-- Swiper -->
<script src="/assets/js/settings.js"></script>
<script src="/assets/js/custom.js"></script> */
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
