import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  requestOTP,
  verifyOTP,
  resendOTP,
  login,
  setUserPassword,
} from "../../api/apiService";
import { useNavigate } from "react-router-dom";

export const Signup = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Persist state across refresh
  useEffect(() => {
    const savedEmail = localStorage.getItem("signupEmail");
    const savedReferralCode = localStorage.getItem("signupReferralCode");
    const savedStep = localStorage.getItem("signupStep");
    const savedUserId = localStorage.getItem("signupUserId");

    if (savedEmail) setEmail(savedEmail);
    if (savedReferralCode) setReferralCode(savedReferralCode);
    if (savedStep) setStep(Number(savedStep));
    if (savedUserId) setUserId(savedUserId);
  }, []);

  useEffect(() => {
    localStorage.setItem("signupEmail", email);
    localStorage.setItem("signupReferralCode", referralCode);
    localStorage.setItem("signupStep", step);
    localStorage.setItem("signupUserId", userId);
  }, [email, referralCode, step, userId]);

  const handleNext = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    try {
      if (step === 1) {
        try {
          const data = await requestOTP(email, referralCode);
          console.log("API Response:", data);
          setUserId(data.data.userId);
          console.log("The Id: ", data.data.userId);
          localStorage.setItem("signupUserId", data.data.userId);

          toast.success("OTP sent to your email!");
          setStep(2);
        } catch (error) {
          console.log("Error details:", error);
          setErrorMessage(error.response?.data?.message);
        }
      } else if (step === 2) {
        // Verify OTP
        const storedUserId = userId || localStorage.getItem("signupUserId");
        if (!storedUserId) throw new Error("User ID not found");
        await verifyOTP(storedUserId, otp);
        toast.success("OTP verified. Proceeding to the next step.");
        setStep(3);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response?.data?.message);
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setLoading(true);

    try {
      const storedUserEmail = email || localStorage.getItem("signupEmail");
      if (!storedUserEmail) throw new Error("User Email not found");
      await setUserPassword(storedUserEmail, password);
      toast.success("Your account is ready. Logging you in...");

      // Step 2: Automatically Login the User
      const loginResponse = await login(storedUserEmail, password);

      // Save user information and token locally
      localStorage.setItem("token", loginResponse.token);
      localStorage.setItem("referral", loginResponse.referralLink);
      localStorage.setItem("user", JSON.stringify(loginResponse.existingUser));
      localStorage.removeItem("signupEmail");
      localStorage.removeItem("signupReferralCode");
      localStorage.removeItem("signupStep");
      navigate("/");
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Signup failed. Please try again."
      );
      toast.error(error.response?.data?.message || "Signup failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <div className="container">
        <div className="account-area style-2">
          {step > 1 && (
            <a
              href="javascript:void(0);"
              className="text-decoration-none back-btn"
              onClick={() => setStep(step - 1)}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                cursor: "pointer",
              }}
            >
              <i
                className="icon feather icon-arrow-left"
                style={{ marginRight: "5px" }}
              ></i>
              Back
            </a>
          )}
          <div className="section-head ps-0">
            {step === 1 && <h2>Can we get your detail?</h2>}
            {step === 2 && <h2>Enter the OTP sent to your Email</h2>}
            {step === 3 && <h2>Can we get your Phone Number?</h2>}
          </div>
          <div className="country-select">
            {step === 1 && (
              <form>
                <div
                  className="bg-white input-group input-group-icon input-mini"
                  style={{ marginBottom: "16px" }}
                >
                  <label
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "8px",
                      color: "#555",
                    }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter your email"
                    style={{ width: "100%", padding: "10px" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div
                  className="bg-white input-group input-group-icon input-mini"
                  style={{ marginBottom: "16px" }}
                >
                  <label
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "8px",
                      color: "#555",
                    }}
                  >
                    Referral Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter referral code"
                    style={{ width: "100%", padding: "10px" }}
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                  />
                </div>
              </form>
            )}
            {step === 2 && (
              <form>
                <div
                  className="bg-white input-group input-group-icon input-mini"
                  style={{ marginBottom: "16px" }}
                >
                  <label
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "8px",
                      color: "#555",
                    }}
                  >
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ width: "100%", padding: "10px" }}
                  />
                </div>
                <button
                  onClick={async () => {
                    try {
                      const storedUserId =
                        userId || localStorage.getItem("signupUserId");
                      await resendOTP(storedUserId, email);
                      alert("OTP resent to your email");
                    } catch (error) {
                      setErrorMessage(
                        error.response?.data?.message ||
                          "Failed to resend OTP. Please try again."
                      );
                    }
                  }}
                  className="btn btn-link"
                  style={{ marginTop: "10px" }}
                >
                  Resend OTP
                </button>
              </form>
            )}
            {step === 3 && (
              <form>
                <div
                  className="bg-white input-group input-group-icon input-mini"
                  style={{
                    marginBottom: "16px",
                    position: "relative",
                  }}
                >
                  <label
                    style={{
                      display: "block",
                      fontWeight: "600",
                      marginBottom: "8px",
                      color: "#555",
                    }}
                  >
                    Set Your Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter your password"
                    style={{
                      width: "100%",
                      padding: "10px",
                    }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    type="button"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => setShowPassword((prev) => !prev)}
                    style={{
                      position: "absolute",
                      top: "80%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                      fontSize: "16px",
                      zIndex: 10,
                    }}
                  >
                    {showPassword ? "🙈" : "👁️"}
                  </button>
                </div>
              </form>
            )}
          </div>
          {errorMessage && (
            <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
          )}
        </div>
      </div>

      <div className="footer fixed bg-white p-b15">
        <div className="container">
          {step < 3 ? (
            <a
              href="javascript:void(0);"
              onClick={handleNext}
              className="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </a>
          ) : (
            <a
              href="javascript:void(0);"
              onClick={handleSubmit}
              className="btn btn-lg btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
