import React, { useState } from "react";

const Settings = () => {
  const [ageMinValue, setAgeMinValue] = useState(10);
  const [ageMaxValue, setAgeMaxValue] = useState(50);
  const [maxValue, setMaxValue] = useState(50);

  const handleMaxChange = (e) => {
    setMaxValue(e.target.value);
  };
  const handleAgeMinChange = (event) => {
    const value = Math.min(Number(event.target.value), ageMaxValue - 1);
    setAgeMinValue(value);
  };

  const handleAgeMaxChange = (event) => {
    const value = Math.max(Number(event.target.value), ageMinValue + 1);
    setAgeMaxValue(value);
  };

  return (
    <div>
      <style>
        {`
          input[type="range"] {
            -webkit-appearance: none;
            appearance: none;
            background: linear-gradient(90deg, #FF50A2 ${maxValue}%, #e6e6e6 ${maxValue}%);
            height: 8px;
            border-radius: 5px;
            outline: none;
            transition: background 0.3s ease-in-out;
          }
          
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #FF50A2;
            border: 2px solid #fff;
            cursor: pointer;
            transition: transform 0.2s ease-in-out;
          }

          input[type="range"]::-webkit-slider-thumb:hover {
            transform: scale(1.2);
          }

          input[type="range"]::-moz-range-thumb {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #FF50A2;
            border: 2px solid #fff;
            cursor: pointer;
          }
        `}
      </style>
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content me-3">
              <a
                href="javascript:void(0);"
                className="text-decoration-none back-btn"
              >
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h6 className="title">Settings</h6>
            </div>
            <div className="mid-content"></div>
            <div className="right-content"></div>
          </div>
        </div>
      </div>
      <div
        className="page-content space-top p-b65"
        style={{
          background: "#f8f9fa",
        }}
      >
        <div className="container">
          <h6 className="title">Account Setting</h6>
          <div
            className="card style-3"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Phone Number</h6>
            </div>
            <div className="card-body">
              <a
                href="javascript:void(0);"
                className="text-decoration-none setting-input"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom1"
                aria-controls="offcanvasBottom"
              >
                <i className="icon dz-flex-box feather icon-phone-call"></i>
                <span>+123 4567 890</span>
              </a>
            </div>
          </div>
          <div
            className="card style-3"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Email Address</h6>
            </div>
            <div className="card-body">
              <a
                href="javascript:void(0);"
                className="text-decoration-none setting-input"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom2"
                aria-controls="offcanvasBottom"
              >
                <i className="icon dz-flex-box feather icon-mail"></i>
                <span>yourname@gmail.com</span>
              </a>
            </div>
          </div>
          <h6 className="title">Location Setting</h6>
          <div
            className="card style-3"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Location</h6>
            </div>
            <div className="card-body">
              <a
                href="javascript:void(0);"
                className="text-decoration-none setting-input"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom3"
                aria-controls="offcanvasBottom"
              >
                <i className="icon dz-flex-box feather icon-map-pin"></i>
                <span>2300 Traverwood Dr.Ann Arbor, MI..</span>
              </a>
            </div>
          </div>
          <h6 className="title">Other</h6>
          <div
            className="card style-3"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Maximum Distance</h6>
              <div className="title font-w600 font-16">
                <span className="example-val title slider-margin-value-max">
                  {maxValue} km
                </span>
              </div>
            </div>
            <div className="card-body py-4">
              <div className="range-slider style-1 w-100">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={maxValue}
                  onChange={handleMaxChange}
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    cursor: "pointer",
                    accentColor: "#FF50A2",
                  }}
                />
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    color: "#FF50A2",
                  }}
                >
                  Selected Value: {maxValue}
                </div>
              </div>
            </div>
          </div>
          <div
            className="card style-3 mb-10"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Age Range</h6>
              <div className="title font-w600 font-16">
                <span className="example-val title slider-margin-value-max">
                  {ageMinValue} - {ageMaxValue}
                </span>
              </div>
            </div>
            <div className="card-body py-4">
              <div
                className="range-slider style-1 w-100"
                style={{ padding: "20px", maxWidth: "400px", margin: "0 auto" }}
              >
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={ageMinValue}
                  onChange={handleAgeMinChange}
                  style={{
                    position: "absolute",
                    width: "100%",
                    cursor: "pointer",
                    zIndex: 2,
                    accentColor: "blue",
                  }}
                />
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={ageMaxValue}
                  onChange={handleAgeMaxChange}
                  style={{
                    position: "absolute",
                    width: "100%",
                    cursor: "pointer",
                    zIndex: 2,
                    accentColor: "red",
                  }}
                />
                <div
                  className="range-slider style-1 w-100"
                  style={{
                    padding: "20px",
                    maxWidth: "400px",
                    margin: "0 auto",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      color: "#FF50A2",
                    }}
                  >
                    Selected Range: {ageMinValue} - {ageMaxValue}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card style-3 mb-10"
            style={{
              marginBottom: "1.25rem",
              backgroundColor: "#fff",
              transition: "all .5s ease-in-out",
              position: "relative",
              border: "1px solid #e6e6e6",
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              className="card-header"
              style={{
                background: "#fff",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
            >
              <h6 className="title mb-0 font-14 font-w500">Show Me</h6>
            </div>
            <div className="card-body">
              <a
                href="javascript:void(0);"
                className="text-decoration-none setting-input"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom4"
                aria-controls="offcanvasBottom"
              >
                <span>Women</span>
                <i className="icon feather dz-flex-box icon-chevron-right ms-auto me-0"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Canvas goes here */}
      {/* Phone Number Canvas */}
      <div
        className="offcanvas offcanvas-bottom"
        tabindex="-1"
        id="offcanvasBottom1"
      >
        <button
          type="button"
          className="btn-close drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header share-style m-0 pb-0">
          <h6 className="title">Phone Number</h6>
        </div>
        <div className="offcanvas-body overflow-visible">
          <form>
            <div className="input-group dz-select">
              <div className="input-group-text">
                <div>+61</div>
              </div>
              <input
                type="number"
                className="form-control"
                value="1234567890"
              />
            </div>
            <a
              href="javascript:void(0);"
              className="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save
            </a>
          </form>
        </div>
      </div>

      {/* Email canvas */}
      <div
        className="offcanvas offcanvas-bottom"
        tabindex="-1"
        id="offcanvasBottom2"
      >
        <button
          type="button"
          className="btn-close drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header share-style m-0 pb-0">
          <h6 className="title">Email Address</h6>
        </div>
        <div className="offcanvas-body">
          <form>
            <div className="input-group input-group-icon">
              <div className="input-group-text">
                <div className="input-icon">
                  <i className="fa-solid fa-envelope"></i>
                </div>
              </div>
              <input
                type="email"
                className="form-control"
                value="yourname@gmail"
              />
            </div>
            <a
              href="javascript:void(0);"
              className="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save
            </a>
          </form>
        </div>
      </div>

      {/* Location canvas */}
      <div
        className="offcanvas offcanvas-bottom"
        tabindex="-1"
        id="offcanvasBottom3"
      >
        <button
          type="button"
          className="btn-close drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header share-style m-0 pb-0">
          <h6 className="title">Change your Address</h6>
        </div>
        <div className="offcanvas-body">
          <form>
            <div className="input-group input-group-icon">
              <div className="input-group-text">
                <div className="input-icon">
                  <i className="icon feather icon-map-pin"></i>
                </div>
              </div>
              <input
                type="email"
                className="form-control"
                value="2300 Traverwood Dr.Ann Arbor, MI 48105 United States"
              />
            </div>
            <a
              href="javascript:void(0);"
              className="btn btn-gradient w-100 dz-flex-box btn-shadow rounded-xl"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save
            </a>
          </form>
        </div>
      </div>

      {/* Interested in Gender */}
      <div
        className="offcanvas offcanvas-bottom p-b65"
        tabindex="-1"
        id="offcanvasBottom4"
      >
        <button
          type="button"
          className="btn-close drage-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="offcanvas-header share-style m-0 pb-0">
          <h6 className="title">Show Me</h6>
        </div>
        <div className="offcanvas-body">
          <div className="radio style-2">
            <label className="radio-label">
              <input type="radio" checked="checked" name="radio2" />
              <span className="checkmark">
                <span className="text">Women</span>
                <span className="check"></span>
              </span>
            </label>
            <label className="radio-label">
              <input type="radio" name="radio2" />
              <span className="checkmark">
                <span className="text">Men</span>
                <span className="check"></span>
              </span>
            </label>
            <label className="radio-label">
              <input type="radio" name="radio2" />
              <span className="checkmark">
                <span className="text">Other</span>
                <span className="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
