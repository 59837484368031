import React, { useState } from "react";

const ProfileDetail = () => {
  return (
    <div>
      <div className="header header-fixed bg-white">
        <div className="container">
          <div className="header-content">
            <div className="left-content">
              <a
                href="javascript:void(0);"
                className="text-decoration-none back-btn"
              >
                <i className="icon feather icon-chevron-left"></i>
              </a>
              <h6 className="title">Connect with User</h6>
            </div>
            <div className="mid-content header-logo"></div>
            <div className="right-content dz-meta"></div>
          </div>
        </div>
      </div>

      <div className="page-content space-top p-b40">
        <div className="container">
          <div className="detail-area">
            <div className="dz-media-card style-2">
              <div className="dz-media">
                <img src="/assets/images/liked/pic2.png" alt="" />
              </div>
              <div className="dz-content">
                <div className="left-content">
                  <h4 className="title">Chelsea, 21</h4>
                </div>
                <div className="dz-icon-box mb-0">
                  <a
                    href="#"
                    className="text-decoration-none icon dz-flex-box dislike"
                  >
                    <i className="flaticon flaticon-cross font-18"></i>
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none icon dz-flex-box super-like"
                  >
                    <i className="fa-solid fa-star"></i>
                  </a>
                  <a
                    href="#"
                    className="text-decoration-none icon dz-flex-box like"
                  >
                    <i className="fa-solid fa-heart"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="detail-bottom-area">
              <div className="about">
                <h6 className="title">Basic information</h6>
                <p className="para-text">
                  Just moved back to jakarata after living at India for 10+
                  years. Di luar terlifiat cenger - center di dalam.
                </p>
              </div>
              <div className="intrests mb-3">
                <h6 className="title">Intrests</h6>
                <ul className="dz-tag-list">
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-camera"></i>
                      <span>Photography</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-music"></i>
                      <span>Music</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-book"></i>
                      <span>Study</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-film"></i>
                      <span>Movies</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-instagram"></i>
                      <span>Instagram</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <i className="icon feather icon-map-pin"></i>
                      <span>Travelling</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="languages mb-3">
                <h6 className="title">Languages</h6>
                <ul className="dz-tag-list">
                  <li>
                    <div className="dz-tag">
                      <span>English</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <span>Spanish</span>
                    </div>
                  </li>
                  <li>
                    <div className="dz-tag">
                      <span>German</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;
