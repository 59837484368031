import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="menubar-area style-2 footer-fixed">
      <div className="toolbar-inner menubar-nav">
        <a href="/" className="nav-link">
          <i className="fa-solid fa-photo-film"></i>
          <span>Feeds</span>
        </a>

        <a href="/connect" className="nav-link">
          <i className="fas fa-users"></i>
          <span>Connect</span>
        </a>

        <a href="/create" className="nav-link">
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          <span>Create</span>
        </a>

        <a href="/chat" className="nav-link">
          <i className="flaticon flaticon1-chat"></i>
          <span>Chat</span>
        </a>

        <a href="/profile" className="nav-link">
          <i class="flaticon flaticon1-avatar"></i>
          <span>Profile</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
