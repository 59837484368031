import React, { useState } from "react";

const CommentsSection = ({ commentsData }) => {
  const [comments, setComments] = useState(commentsData);
  const [newComment, setNewComment] = useState("");

  // Add a new comment
  const handlePostComment = () => {
    if (newComment.trim()) {
      const newCommentObject = {
        username: "current_user",
        profilePic: "https://i.postimg.cc/P56fpzX0/user-3.png",
        timestamp: "just now",
        content: newComment,
        likes: 0,
        dislikes: 0,
        replies: [],
      };
      setComments([newCommentObject, ...comments]);
      setNewComment("");
    }
  };

  // Toggle replies visibility
  const toggleReplies = (index) => {
    const updatedComments = [...comments];
    updatedComments[index].showReplies = !updatedComments[index].showReplies;
    setComments(updatedComments);
  };

  return (
    <section className="comment-section">
      <div className="comment-content">
        <div ClassName="thumbnail">
          <img
            src="https://i.postimg.cc/VLGJycxx/thumbnail1.jpg"
            alt="thumbnail"
          />
        </div>
        <div ClassName="comment-header"></div>
        <div ClassName="fold"></div>
        {/* Header */}
        <div className="heading">
          <h1>Comments</h1>
          <button>
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        {/* Timeline Filters */}
        <div className="timeline">
          <button style={{ background: "#fff" }}>
            <p>Top</p>
          </button>
          <button className="select">
            <p>Timed</p>
          </button>
          <button className="select">
            <p>Newest</p>
          </button>
        </div>

        {/* Notice */}
        <div className="notice">
          <p>
            Remember to keep comments respectful and to follow our{" "}
            <span>
              <button type="button">Community Guidelines</button>
            </span>
          </p>
        </div>

        {/* Comments List */}
        <div className="comments-list">
          {comments.map((comment, index) => (
            <div className="comment" key={index}>
              <div className="profile">
                <img src={comment.profilePic} alt="User Profile" />
              </div>
              <div className="comment_main">
                <div className="line1">
                  <p>
                    @{comment.username} • {comment.timestamp}
                  </p>
                </div>
                <div className="line2">
                  <p>{comment.content}</p>
                </div>
                <div className="other">
                  <button>
                    <ion-icon name="heart-outline"></ion-icon>
                    <p>{comment.likes}</p>
                  </button>
                  <button>
                    <ion-icon name="heart-dislike-outline"></ion-icon>
                  </button>
                  <button>
                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                  </button>
                </div>
                {comment.replies && comment.replies.length > 0 && (
                  <div className="replies">
                    <button onClick={() => toggleReplies(index)}>
                      <p>
                        {comment.showReplies ? "Hide" : "Show"}{" "}
                        {comment.replies.length} replies
                      </p>
                    </button>
                    {comment.showReplies && (
                      <div className="replies-list">
                        {comment.replies.map((reply, replyIndex) => (
                          <div className="reply" key={`${index}-${replyIndex}`}>
                            <p>
                              @{reply.username}: {reply.content}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Add Comment Section */}
        <div className="post">
          <div className="profile">
            <img
              src="https://i.postimg.cc/P56fpzX0/user-3.png"
              alt="User Profile"
            />
          </div>
          <div className="comment">
            <input
              type="text"
              placeholder="Add a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
            />
            <button onClick={handlePostComment}>Post</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommentsSection;
