import React, { useState } from "react";

const Packages = () => {
  return (
    <div>
      <div className="header header-fixed bg-white border-0">
        <div className="container">
          <div className="header-content">
            <div className="left-content">
              <h4 className="title">Subscriptions</h4>
            </div>
            <div className="mid-content"></div>
            <div className="right-content"></div>
          </div>
        </div>
      </div>
      <div className="page-content space-top p-b30">
        <div className="container">
          <div className="account-area style-2">
            <div className="section-head ps-0">
              <p>Each earning cycle may take 3-4weeks to complete</p>
            </div>
            <div className="row g-2 mb-2">
              <div class="col-6">
                <div class="dz-demo-card bg-3">
                  <div class="left-content">
                    <h2 class="title">Smart</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$38</strong> per earning cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$12</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="dz-demo-card bg-3">
                  <div class="left-content">
                    <h2 class="title">Startup</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$72</strong> per earning cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$30</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="dz-demo-card bg-1">
                  <div class="left-content">
                    <h2 class="title">Bluechrest</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$195 </strong> per earning cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$80</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="dz-demo-card bg-3">
                  <div class="left-content">
                    <h2 class="title">Fidle Boss</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$383 </strong> per earning cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$150</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="dz-demo-card bg-3">
                  <div class="left-content">
                    <h2 class="title">Bloomers</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$495 </strong> per earning cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$200</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="dz-demo-card bg-1">
                  <div class="left-content">
                    <h4 class="title">Blum Parent</h4>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "5px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$1120 </strong> per earning
                      cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$500</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="dz-demo-card bg-2">
                  <div class="left-content">
                    <h2 class="title">Star Father</h2>
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "-10px",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      Buy & Earn up to: <strong>$2650 </strong> per earning
                      cycle
                    </p>
                    <p class="display-6 fw-bold p-b10">$1000</p>
                    <a href="w3badoo/home.html" class="btn btn-sm preview-btn">
                      subscribe now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
